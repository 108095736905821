<template>
  <v-card class="pa-8" width="100%">
    <loading :active.sync="loading"></loading>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-row class="pt-4 pb-10">
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">บันทึก</v-btn>
      </v-row>
      <h3 class="pa-4" style="font-weight: 800">รายละเอียดออเดอร์</h3>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>หมายเลขคำสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.transactionNo }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>วันและเวลาสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertDate(tranxDetail.createdAt) }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ชื่อผู้สั่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ tranxDetail.receiveFromFirstName || user.firstName }}
            {{ tranxDetail.receiveFromLastName || user.lastName }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>หมายเลขโทรศัพท์</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.receiveFromTel || user.telNumber }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ซื้อให้ผู้อื่น</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.buyToFlag ? "ใช่" : "ไม่ใช่" }} </span>
        </v-col>
      </v-row>
      <v-row v-if="tranxDetail.buyToFlag">
        <v-col cols="12" md="3" sm="3"><span>ซื้อให้ ชื่อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ tranxDetail.receiveFirstName }}
            {{ tranxDetail.receiveLastName }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="tranxDetail.buyToFlag">
        <v-col cols="12" md="3" sm="3"
          ><span>ซื้อให้ หมายเลขโทรศัพท์</span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.receiveTel }} </span>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>ประเภทการสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span v-if="isDelivery">จัดส่งตามที่อยู่</span>
          <span v-if="!isDelivery">เก็บในตู้เซพ</span>
        </v-col>
      </v-row> -->
      <!-- <v-row v-if="isDelivery">
        <v-col cols="12" md="3" sm="3"><span>สถานที่จัดส่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ deliveryAddress.address }} แขวง/ตำบล:
            {{ deliveryAddress.subDistrict }} เขต/อำเภอ:
            {{ deliveryAddress.District }} จังหวัด:
            {{ deliveryAddress.provice }} รหัสไปรษณีย์:
            {{ deliveryAddress.postcode }}
          </span>
        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>สถานะจัดส่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ deliveryStatus }}</span>
        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>สั่งเมื่อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertDate(createdAt) }}</span>
        </v-col>
      </v-row> -->
      <v-row v-if="tranxDetail.agentId">
        <v-col cols="12" md="3" sm="3"><span>ซื้อผ่านตัวแทน</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ tranxDetail.agentData.firstName }}
            {{ tranxDetail.agentData.lastName }} ({{
              tranxDetail.agentData.agentNo
            }})</span
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"><span>สถานะ</span> </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-select
            v-model="status"
            :items="itemsStatus"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <!-- <v-row dense>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>สถานะการจัดส่ง</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-select
            v-model="deliveryStatus"
            :items="itemsDeliveryStatus"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>หมายเหตุ</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-text-field placeholder="หมายเหตุ" v-model="remark" dense solo>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>แก้ไขโดย</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <span>{{ showUserName || "-" }}</span>
          <!-- <v-text-field placeholder="หมายเหตุ" v-model="remark" dense solo>
          </v-text-field> -->
        </v-col>
      </v-row>
      <!-- <v-row align="center" justify="center" class="mt-1">
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined class="pa-4">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" class="pt-6">
                <span>อัพโหลดรูปสลิปที่นี่</span>
                <span style="color: red">*</span>
              </v-col>
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <v-row align="center" justify="center">
                  <v-img
                    v-model="picture_1"
                    @click="changePic1()"
                    src="@/assets/upload.png"
                    class="mr-3"
                    max-width="30"
                  >
                  </v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col
                    cols="5"
                    md="5"
                    class="
                      pl-5
                      pr-5
                      pb-5
                      row
                      fill-height
                      sortable-list
                      align-center
                    "
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage()"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="0.6"
                        contain
                        @click="newWindow(showImage)"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/png"
                  @change="showPicture1($event)"
                  style="display: none"
                  hidden
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row v-if="tranxDetail.slipImgUrl != ''">
        <v-col cols="12" md="3" sm="3"><span>รูป slip</span> </v-col>
        <!-- <v-col cols="12" md="9" sm="9">
          <v-img
            :src="tranxDetail.slipImgUrl"
            max-width="400px"
            :max-height="auto"
          ></v-img>
        </v-col> -->
        <v-col cols="12" md="9" sm="9">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="10" md="10" sm="10" xs="10" class="ma-4" id="step-3">
                <span>รูปแสกนสลิปโอนเงิน</span>

                <v-img :src="showImage2" width="200px"></v-img>
                <v-text-field
                  v-model="picture_2"
                  @click="changePic2()"
                  :placeholder="imageName2"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure2"
                  accept="image/*"
                  @change="showPicture2($event)"
                  style="display: none"
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span>รูปแสกนตั๋วพาวเวอร์บอล</span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="10" md="10" sm="10" xs="10" class="ma-4" id="step-3">
                <span>รูปแสกนตั๋วพาวเวอร์บอล</span>
                <span style="color: red">*</span>
                <v-img :src="showImage" width="200px"></v-img>
                <v-text-field
                  v-model="picture_1"
                  @click="changePic1()"
                  :placeholder="imageName1"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/png"
                  @change="showPicture1($event)"
                  style="display: none"
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
      <h3 class="pt-8" style="font-weight: 800">ออเดอร์ที่สั่ง</h3>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="tranxDetail.powerballData"
            :search="search"
            :items-per-page="10"
            disable-sort
            hide-default-footer
            disable-pagination
            no-data-text="ไม่มีข้อมูล"
            class="elevation-1"
          >
            <template v-slot:[`item.count`]="{ index }">
              <!-- <span>{{ item.count.indexOf() + 1 }}</span> -->
              <span>{{ index + 1 }}</span>
            </template>

            <template v-slot:[`item.powerballData`]="{ item }">
              <v-chip
                color="#005BA6"
                style="margin-right: 1px"
                v-for="(powerball, index) of item.set"
                :key="`powerball_${index}`"
              >
                <span style="font-size: 1rem; font-weight: 700; color: #fff">{{
                  powerball
                }}</span>
              </v-chip>
              <!-- <span v-for="(powerball, index) of item.set" :key="`powerball_${index}`">
                  {{powerball}}
                </span> -->
              <v-chip color="red" style="margin-right: 0px">
                <span style="font-size: 1rem; font-weight: 700; color: #fff">{{
                  item.powerBall
                }}</span>
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6" md="6">
          <span style="font-size: 20px">
            <b>Power Play: </b>
          </span>
          <!-- </v-col>
          <v-col cols="6" md="6"> -->
          <span style="font-size: 20px">
            <!-- <v-checkbox v-model="tranxDetail.isPowerPlay" disabled>
          </v-checkbox> -->
            <span v-if="tranxDetail.isPowerPlay">มี</span>
            <span v-if="!tranxDetail.isPowerPlay">ไม่มี</span>
          </span>
        </v-col>
        <v-col cols="12" md="12" v-if="tranxDetail.multipleAmt > 1">
          <p style="font-size: 20px">
            <b>ซื้อหลายงวด:</b> จำนวน {{ tranxDetail.multipleAmt }} งวด
          </p>
        </v-col>
        <v-col cols="12" md="12">
          <p style="font-size: 20px">
            <b>ช่องทางการชำระเงิน:</b>
            {{
              tranxDetail.paymentType == "PAYSOLUTION"
                ? "PAYSOLUTION"
                : "โอนเงิน"
            }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          v-if="tranxDetail.paymentType == 'PAYSOLUTION'"
        >
          <p style="font-size: 20px">
            <b>รหัสชำระเงิน:</b> {{ tranxDetail.paysolutionId }}
          </p>
        </v-col>
        <v-col cols="12" md="12">
          <p style="font-size: 20px">
            <b>จำนวนเงินทั้งหมด:</b> {{ tranxDetail.totalPrice }} บาท
          </p>
        </v-col>

        <v-col cols="12" md="12" v-if="powerBallStatusData">
          <p style="font-size: 20px">
            <b>สถานะจากตัวแทน POWERBALL อเมริกา:</b>
            {{ powerBallStatusData.result_description }}
          </p>
        </v-col>
        <v-col cols="12" md="12" v-if="powerBallStatusData">
          <p style="font-size: 20px">
            <b>Request ID (กรณีติดต่อเจ้าหน้าที่ Powerball):</b>
            {{ powerBallStatusData.request_id }}
          </p>
        </v-col>
        <v-col cols="12" md="12" v-if="powerBallStatusData">
          <p style="font-size: 20px">
            <b>Customer Tag (กรณีติดต่อเจ้าหน้าที่ Powerball):</b>
            {{ powerBallStatusData.customer_tag }}
          </p>
        </v-col>
        <v-col cols="12" md="12" v-if="powerBallStatusData">
          <p
            style="font-size: 20px"
            v-if="!powerBallStatusData.is_ticket_scanned"
          >
            <b>รูปสลากสแกนจากใบจริง:</b> ทางเราจะสแกนสำเนาสลากก่อนการออกรางวัล
          </p>
          <p
            style="font-size: 20px"
            v-if="powerBallStatusData.is_ticket_scanned"
          >
            <b>รูปสลากสแกนจากใบจริง:</b>
            <!-- {{ powerBallStatusData.scanned_url }} -->
          </p>
          <v-img
            :src="powerBallStatusData.scanned_url"
            max-height="800px"
            max-width="400px"
          ></v-img>
        </v-col>
        <v-col cols="12">
          <v-btn color="green" dark @click="sendSuccessSMS(user.telNumber)"
            >ส่ง SMS ให้ผู้สั่งซื้อ</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            color="green"
            dark
            @click="sendSuccessSMS(tranxDetail.receiveTel)"
            >ส่ง SMS ให้ผู้อื่น</v-btn
          >
        </v-col>
      </v-row>

      <br />
      <br />
      <v-row>
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import moment from "moment";
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      search: "",
      remark: "",
      count: 1,
      id: "",
      adminName: "",

      status: "",
      itemsStatus: [
        { text: "มีปัญหา", value: "มีปัญหา" },
        { text: "ยกเลิก", value: "ยกเลิก" },
        // { text: "อนุมัติ/ยืนยันสลิปถูกต้อง", value: "สั่งซื้อสำเร็จ" },
        { text: "รอการชำระเงิน", value: "รอการชำระเงิน" },
        { text: "ชำระเงินสำเร็จ", value: "ชำระเงินสำเร็จ" },
        { text: "สั่งซื้อสำเร็จ(อัพโหลดตั๋วแล้ว)", value: "สั่งซื้อสำเร็จ" },
      ],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      deliveryAddress: "",
      deliveryStatus: "",
      itemsDeliveryStatus: [
        { text: "รอการจัดส่ง", value: "WAITING" },
        { text: "จัดส่งแล้ว", value: "SENT" },
      ],
      isDelivery: false,
      lotteryDate: "",
      lotteryTransaction: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      pic2: "",
      picture_2: "",
      showImage2: "",
      imageName2: "",
      slipImgUrl: "",
      slipImg: "",
      status: "",
      totalPrice: "",
      user: [],
      // orderProducts: [],
      headers: [
        {
          text: "แถวที่",
          value: "count",
          align: "center",
        },
        {
          text: "เลขที่ซื้อ",
          value: "powerballData",
          align: "center",
          width: "500px",
        },
      ],
      // createdAt: "2021-04-24T18:16:52.000Z"
      // id: 139
      // lotteryDate: "2020-08-16"
      // lotteryNo: 309072
      // owner: "admin"
      // price: 80
      // set: 23
      // stock: 0
      // time: 34
      // updatedAt: "2021-06-29T03:25:01.000Z"
      // urlImage: "https://readylotserv
      // lottery: (...),
      tranxDetail: [],
      powerBallStatusData: null,
      trasactionId: "",
      showUserName: "",
      keytab: "",
    };
  },
  created() {
    this.keytab = this.$route.query.tab;
    console.log("router", this.keytab);
    var orderdata = JSON.parse(
      Decode.decode(localStorage.getItem("orderdata"))
    );
    console.log("orderdata", orderdata);
    if (orderdata.id) {
      this.id = orderdata.id;
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log("userdata", user);
      this.adminName = user.user.firstName + " " + user.user.lastName;
      this.getOneOrder();
    } else if (orderdata.transactionId) {
      this.id = orderdata.transactionId;
      this.getOneOrder();
    }
  },
  methods: {
    async getPowerballStatus() {
      // console.log("user", user.token);

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/transactionMegaMillions/getPowerballData/` + this.id,
        {}
      );
      console.log("response powerball", response.data.data);
      this.powerBallStatusData = response.data.data;
    },
    newWindow(val) {
      window.open(val);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY HH:mm");
    },
    async sendSuccessSMS(tel) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionMegaMillions/sendSuccessSMSPowerball/${tel}/${this.tranxDetail.transactionNo}`
        // 'http://localhost:8081/categories/'
      );

      this.$swal.fire({
        icon: "success",
        text: `ส่งข้อความสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    async getOneOrder() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionMegaMillions/getPowerballTrx/` + this.id,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getOneOrder", response.data);
      this.tranxDetail = response.data.data;
      this.tranxDetail.powerballData = JSON.parse(
        this.tranxDetail.selectedBall
      );
      const orderdata = response.data.data;
      // this.id = orderdata.id;
      this.deliveryStatus = orderdata.deliveryStatus;
      this.lotteryTransaction = orderdata.lotteryTransaction;
      this.showImage2 = orderdata.slipImgUrl;
      this.showImage = orderdata.scanedTicket;
      this.status = orderdata.status;
      this.totalPrice = orderdata.totalPrice;
      this.user = orderdata.user;
      this.isDelivery = orderdata.isDelivery;
      this.remark = orderdata.remark;
      if (this.isDelivery) {
        this.deliveryAddress = orderdata.deliveryAddress;
      }
      this.showUserName = orderdata.adminName;
      this.getPowerballStatus();
    },

    cancel() {
      // this.$router.push("ManagePowerballOrder");
      this.$router.push({
        path: "/ManagePowerballOrder",
        query: {
          tab: this.keytab,
        },
      });
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        //   this.showImage2 = orderdata.slipImgUrl;
        // this.showImage = orderdata.scanedTicket;
        const data = {
          status: this.status,
          adminName: this.adminName,
          remark: this.remark,
        };
        if (this.pic2 !== "") {
          data.slipImg = this.pic2;
        } else {
          data.slipImgUrl = this.showImage2;
        }
        if (this.pic1 !== "") {
          data.scanedTicket = this.pic1;
        } else {
          data.scanedTicketUrl = this.showImage;
        }
        console.log("data submittttttt", data);
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/transactionMegaMillions/updatePowerBall/` + this.id,
          data,
          auth
        );
        console.log("update status", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `อัพเดตข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push({
            path: "/ManagePowerballOrder",
            query: {
              tab: this.keytab,
            },
          });
          this.loading = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `อัพเดตข้อมูลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.slipImg = "";
      this.showImage = "";
      this.imageName1 = "";
    },
  },
};
</script>
